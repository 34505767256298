.footer {
  color: gray;
  padding-bottom: 20px ;
  text-align: center;
  font-size: 14px;  
  position: relative;  
  bottom: 0;
  width: 100%;
  background-color: black;
}


.footer_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer_social {
  margin-bottom: 10px;
  display: flex;
}

.footer_social a {
  color: white;

  font-size: 20px;
  margin: 0 10px;
  transition: color 0.3s;
}

.footer_social a:hover {
  color: #500075;
}

.footer_text p{
  margin-top: 10px;
  color: gray;

}

.footer_icon {
  vertical-align: middle;
}
.footer_links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 1em;
}
.footer_link {
  color: gray;

  text-decoration: none;
}

.footer_link:hover {
  text-decoration: underline;
  color: white;

}

.footer_link_mobile {
  color: gray;

  text-decoration: none;
}

.footer_link_mobile:hover {
  text-decoration: underline;
  color: white;

}
.book_reclam img {
width: 60px;
cursor: pointer;
}
@media (max-width: 768px) {
  .footer_link_mobile {
    display: none;
  }
}
