.homeContainer {
    background: linear-gradient(135deg, #53676c86 0%, #53676c86 100%),
    url("../../assets/Images/VITAL\ CLIC\ IMAGEN1.png");  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}
.logo img{
    width: 400px;
}

.SearchForm_container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Mantiene el contenido centrado verticalmente */
  align-items: center;
  width: 800px;
  max-width: 100%;
  background-color: rgba(255, 255, 255, 0.811);
  border-radius: 3em;
  margin: 0 auto;
  padding: 2em;
  height: 450px;
}


  
.SearchForm_container h2 {
    color: #53676c;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 25px;
  }
  
  .modeToggle {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    gap: 2em;
  }
  
  .button {
    padding: 10px 10px ;
    font-size: 16px;
    color: #53676c;
    border: 2px solid #53676c;
    background-color: transparent;
    cursor: pointer;
    flex: 1;
    max-width: 150px;
    transition: background-color 0.3s;
  }
  
 .form {
  width: 100%;
  max-width: 600px;
 }
  
  .active {
    background-color: #8ca7a6;
    color: white;
    border: none;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .select {
    width: 100%;
    max-width: 800px;
    margin: auto;
    display: grid;
    gap: 1em;
    height: 2.5em;
    outline: none;
    border: none 1px solid #53676c;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    color: black;
    padding-left: 10px;
  }
  
  .searchButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    font-size: 16px;
    color: #fff;
    background-color: #8ca7a6;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    font-weight: bold;
    width: 200px;
    margin: auto;
  }
  
  .searchButton:hover {
    background-color: #728f96;
  }
  
  .searchIcon {
    margin-left: 8px;
  }
  

  @media screen and (max-width: 768px) {
    .logo {
  display: none;
  }

  .SearchForm_container h2 {
    font-size: 18px;
 
  }

  .SearchForm_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 800px;
    max-width: 100%;
    background-color: rgba(255, 255, 255, 0.811);
    border-radius: 0;
    margin: 0 auto;
    padding: 2em ;
  }
  }