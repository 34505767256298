.container {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .table th,
  .table td {
    border: 1px solid #8ca7a6;
    padding: 10px;
    text-align: center;
  }
  .table th {
    background-color: #8ca7a6;
    color: white;

  }
  .button {
    padding: 5px 10px;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .button:hover {
    background-color: darkred;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .confirmButton {
    padding: 10px 20px;
    background-color: green;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .cancelButton {
    padding: 10px 20px;
    background-color: gray;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .confirmButton:hover {
    background-color: darkgreen;
  }
  
  .cancelButton:hover {
    background-color: darkgray;
  }
  
  .viewInformation {
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.5s ease;
  }

  .viewInformation:hover {
  color: #8ca7a6;
  }