.date-picker-container {
  width: 100%;
  max-width: 800px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, sans-serif;
}

  
  .date-picker-container h2 {
    font-size: 1.5rem;
    color: #4a5b57;
    margin-bottom: 10px;
  }
  .hr {
    border-bottom: 1px solid #cfd8dc;
   margin-top: 2em;
   margin-bottom: 2em;
  }
  
/* Para las celdas de los días de la semana */
.react-datepicker__day-name {
  display: inline-flex;
  justify-content: center;
  width: 3rem;  /* Asegura que los nombres de los días de la semana tengan el mismo tamaño que las celdas de los días */
  font-size: 1.5rem;
  text-align: center;
  padding: 0.5rem 0;
}

.react-datepicker__navigation {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.5rem;
}


.react-datepicker {
  font-size: 1.5rem; /* Aumenta el tamaño del calendario */
}

.react-datepicker__day {
  width: 3rem; /* Aumenta el tamaño de los días */
  height: 3rem;
  font-size: 1.5rem;
}

.react-datepicker__header {
  font-size: 1.5rem;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  font-size: 1.5rem;
}

.react-datepicker__navigation {
  width: 3rem;
  height: 3rem;
}
