.container {
    padding: 20px;
    max-width: 900px;
    margin: 5em auto;
  }
  
  .title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .introduction {
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .subTitle {
    font-size: 1.8rem;
    margin-top: 20px;
    color: #333;
  }
  
  .text {
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 15px;
    }
  
    .title {
      font-size: 2rem;
    }
  
    .subTitle {
      font-size: 1.5rem;
    }
  
    .text {
      font-size: 0.95rem;
    }
  }
  