/* Contenedor principal */
.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/Images/VITAL\ CLIC\ IMAGEN2.png'); /* Cambia por la ruta de tu imagen de fondo */
    background-size: cover;
    background-position: center;
    padding: 20px;
  }
  
  /* Título */
  .title {
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  /* Formulario */
  .form {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
  }
  
  /* Etiquetas */
  .label {
    font-size: 1rem;
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  /* Inputs */
  .input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ffffff;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    margin-bottom: 10px;
    outline: none;

  }
  
  /* Botón */
  .button {
    margin-top: 20px;
    padding: 10px;
    font-size: 1.2rem;
    color: #4a5b57;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  transition: background-color 0.5s ease;

  }
  
  .button:hover {
    background-color: #4a5b57;
    color: white;
  }
  

  @media screen and (max-width:768px) {
    .title {
      font-size: 1.5rem;
     
    }
  }