
.textdependents{
    display: flex;
    justify-content: center;
    gap: 0.2em;
    }
@media screen and (max-width: 767px ) {
    .textdependents{
        flex-direction: column;
        }
}